(function ($, w) {
    w.References = {
        timerLoad: null,

        init: function () {
            References.initEvents();
        },

        initEvents: function () {
            // On écoute le clavier dans le document
            $(document).on('keyup', References.onKeyupDocument);

            // over/out btn actions références
            jQuery('#overlay-references').on('mouseenter', '.reference-inner .actions .btn', References.onMouseenterBtnActions);
            jQuery('#overlay-references').on('mouseleave', '.reference-inner .actions .btn', References.onMouseleaveBtnActions);

            // Scroll et resize
            $('#overlay-references').bind('scroll', References.onScrollOverlayReferences);
            $(w).bind('resize', References.onResizeOverlayReferences);
        },

        open: function (referenceURL) {
            // Si aucune référence chargée
            if(!$('body').hasClass('reference-opened')) {
                $('#overlay-references .reference-wrapper').html('');

                // On ajoute la classe reference-open au body
                $('body').addClass('reference-opened');
                clearTimeout(References.timerLoad);
                References.timerLoad = setTimeout(function () {
                    $('body').addClass('overflow-hidden');
                    $('#overlay-references').removeClass('overflow-hidden').addClass('loading');

                    // On charge la référence
                    References.loadReference(referenceURL);
                }, 400);
            }
            else{
                $('#overlay-references').removeClass('loaded').addClass('unload loading');

                clearTimeout(References.timerLoad);
                References.timerLoad = setTimeout(function(){
                    $('#overlay-references').removeClass('unload');
                    // On charge la référence
                    References.loadReference(referenceURL);
                }, 1000);
            }
        },

        close: function () {
            $('body').removeClass('overflow-hidden');
            $('#overlay-references').removeClass('loaded').addClass('overflow-hidden');
            setTimeout(function(){
                $('body').removeClass('reference-opened');

                $('[data-menuid="references"]', Header.wrapper).trigger('click');
            }, 100);
        },


        onMouseenterBtnActions: function (e) {
            jQuery(e.currentTarget).width(jQuery('.btn-inner', jQuery(e.currentTarget)).outerWidth());
        },

        onMouseleaveBtnActions: function (e) {
            jQuery(e.currentTarget).css('width', '');
        },

        loadReference: function (referenceURL) {
            $('#overlay-references').scrollTop(0);
            // Prepare loader
            TweenLite.set($('#loader-outline'), {drawSVG: '0%'});

            $.ajax({
                url: referenceURL,
                complete: function(jqXHR, textStatus){
                    TweenLite.to($('#loader-outline'), 0.3, {drawSVG: '10%'});
                    References.setPageContent(jqXHR.responseText);
                }
            });
        },

        documentHtml: function(html){
            // Prepare
            var result = String(html)
                .replace(/<\!DOCTYPE[^>]*>/i, '')
                .replace(/<(html|head|body|title|meta|script)([\s\>])/gi,'<div id="document-$1"$2')
                .replace(/<\/(html|head|body|title|meta|script)\>/gi,'</div>');

            // Return
            return $.trim(result);
        },

        setPageContent: function(data){
            var $data = $(References.documentHtml(data)),
                $dataBody = $data.find('#document-body:first'),
                contentHtml, bodyClasses;

            // Fetch the content
            contentHtml = $($dataBody).find('#overlay-references .reference-wrapper').html();
            if ( !contentHtml ) {
                document.location.href = '/';
                return false;
            }

            // Update the title
            document.title = $data.find('#document-title:first').text();
            try {
                document.getElementsByTagName('title')[0].innerHTML = document.title.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; ');
            }
            catch ( Exception ) { }

            // Update the content
            var numImagesLoaded = 0;
            $('#overlay-references .reference-wrapper').html(contentHtml).ajaxify().responsiveImage().imagesLoaded()
                .always( function(imgLoad) {
                    // Hauteur et offset du contenu
                    References.calcSizeElementsContentReference();
                    // Hauteur wrapper
                    References.setHeightReferenceWrapper();

                    References.setPositionReferenceImages();

                    TweenLite.to($('#loader-outline'), 1, {drawSVG: '100%', onComplete: function () {
                            $('#overlay-references').removeClass('loading').addClass('loaded');
                        }
                    });
                })
                .progress( function (imgLoad, image) {
                    numImagesLoaded++;

                    var percentLoaded = 10 + (numImagesLoaded / imgLoad.images.length * 90);

                    TweenLite.to($('#loader-outline'), 1, {drawSVG: percentLoaded + '%'});
                });
        },

        calcSizeElementsContentReference: function () {
            $('.content', $('#overlay-references')).css('top', '');

            heightContentReference = $('.content', $('#overlay-references')).outerHeight(true);
            posTopContentReference = parseInt($('.content', $('#overlay-references')).css('top'), 10);
            deltaSetPosTopContentReference = Main.viewport.height - heightContentReference - posTopContentReference;

            References.setPosTopContentReference();
        },

        setPosTopContentReference: function () {
            if (Main.viewport.width > 1280 || !MainConfig.isUserTouching) {
                var curScrollTop = $('#overlay-references').scrollTop();
                var newPosTop = posTopContentReference + curScrollTop;
                if (deltaSetPosTopContentReference < 0){
                    newPosTop += deltaSetPosTopContentReference;
                }
                if (newPosTop < posTopContentReference){
                    newPosTop = posTopContentReference;
                }
                $('.content', $('#overlay-references')).css({
                    top: newPosTop
                });
            }
        },

        setHeightReferenceWrapper: function () {
            $('.reference-wrapper', $('#overlay-references')).css('height', '');
            if(heightContentReference+posTopContentReference > $('#overlay-references .reference-wrapper').outerHeight()){
                $('.reference-wrapper', $('#overlay-references')).height(heightContentReference+posTopContentReference);
            }
        },

        setPositionReferenceImages: function () {
            // On boucle sur les images de la référence
            var lastImageHeight = 0;
            if(Main.viewport.width >= 640) {
                $('#overlay-references .reference-wrapper .images img').each(function () {
                    // Si ce n'est pas la première image
                    if (lastImageHeight > 0) {
                        $(this).css('margin-top', -(lastImageHeight * 0.3));
                    }
                    lastImageHeight = $(this).outerHeight();
                });
            }
        },

        onKeyupDocument: function (e) {
            if(e.keyCode === 27 && !$('body').hasClass('single-kh_reference')){
                References.close();
            }
        },

        onClickBtnClose: function (e) {
            References.close();
        },

        onScrollOverlayReferences: function (e) {
            References.setPosTopContentReference();
        },

        onResizeOverlayReferences: function (e) {
            w.waitForFinalEvent(function(){
                if($('body').hasClass('reference-opened')) {
                    // Hauteur et offset du contenu
                    References.calcSizeElementsContentReference();
                    // Hauteur wrapper
                    References.setHeightReferenceWrapper();
                    // Position images gallery
                    References.setPositionReferenceImages();
                }
            }, 250, 'resizeWindowReferences');
        }
    };

})($, window);