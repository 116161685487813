(function ($, w) {
    w.Navigation = {
        wrapper: $( 'body' ),
        rootUrl: '',
        isHandlingCurrentMenu: false,
        lastElementEntered: null,
        lastSegments: [],
        pagesViewed: [],
        segmentHome: 'accueil',
        segmentReferences: 'references',
        scenesActiveMode: [],

        init: function () {
            // If single reference, stop
            if ($('body').hasClass('single-kh_reference')) {
                return;
            }

            Navigation.initEvents();

            // Root URL
            Navigation.rootUrl = History.getRootUrl();
            // Internal Helper
            $.expr[':'].internal = function (obj, index, meta, stack) {
                // Prepare
                var
                    $this = $(obj),
                    url = $this.attr('href') || '',
                    isInternalLink;
                // Check link
                isInternalLink = url.substring(0, Navigation.rootUrl.length) === Navigation.rootUrl || url.indexOf(':') === -1;
                // Ignore or Keep
                return isInternalLink;
            };

            // Ajaxify Helper
            $.fn.ajaxify = function () {
                // Prepare
                var $this = $(this);
                // Ajaxify
                $this.find('a:internal:not(.no-ajax):not(.chosen-single)').click(function (event) {
                    if ($(event.target).closest('.no-ajax').length === 0) {
                        // Continue as normal for cmd clicks etc
                        if (event.which === 2 || event.metaKey) {
                            return true;
                        }

                        var url = $(this).attr('href');
                        var newRouteSegment = Navigation.getRouteSegmentsFromUrl(url);
                        var isHomeSegment = newRouteSegment[0] === Navigation.segmentHome || newRouteSegment[0] === '';
                        var title = isHomeSegment ? DEFAULT_TITLE : ($(this).attr('title') || DEFAULT_TITLE);
                        if (title !== DEFAULT_TITLE) {
                            title = title + ' - ' + DEFAULT_TITLE;
                        }

                        History.pushState(null, title, url);
                        newState = History.getState();

                        event.preventDefault();
                        return false;
                    }
                });

                // Chain
                return $this;
            };

            // Ajaxify our Internal Links
            $('body').ajaxify();

            // Bind to State Change
            History.Adapter.bind(w, 'statechange', function () { // Note: We are using statechange instead of popstate
                var State = History.getState(); // Note: We are using History.getState() instead of event.state
                Navigation.setPage(State.url, State.title);
            });
        },

        initEvents: function () {
            // Bind window scroll
            $(w).on('scroll', Navigation.onScrollWindow);
        },

        initInitialPage: function () {
            var State = History.getState();

            // Prevent double hit to GA
            Navigation.pagesViewed.push(Navigation.getGAUrlToStat(State.url));

            Navigation.setPage(State.url, State.title);
            Navigation.initHandleCurrentMenu();
        },

        initHandleCurrentMenu: function(){
            // Get items
            var elements = document.querySelectorAll("[data-relmenuid]");

            // Add scene for each item
            for (var i = 0; i < elements.length; i++) {
                var curMenuId = $(elements[i]).attr('id');
                Navigation.scenesActiveMode[curMenuId] = new ScrollMagic.Scene({
                    triggerElement: '#' + curMenuId,
                    triggerHook: curMenuId === 'contact-bloc-fake' ? 'onEnter' : 0.4,
                    duration: Navigation.getSectionHeight(curMenuId)
                })
                    .addTo(MainConfig.scrollMagicCtrl)
                    .on('enter leave', Navigation.onEnterElementSection);
            }
        },

        onEnterElementSection: function (e) {
            var element = $(e.target.triggerElement());
            var relMenuId = element.data('relmenuid');

            if (relMenuId === 'contact' && e.type === 'leave' && e.target.controller().info('scrollDirection') === 'REVERSE') {
                element = $('#competences-bloc');
                relMenuId = element.data('relmenuid');
            }

            // If element is visible
            if(element.is(':visible') && element.css('visibility') !== 'hidden') {
                if (!Main.isScrolling && !Main.isLoadingDataOnStart) {
                    Navigation.isHandlingCurrentMenu = true;

                    var routeSegments = Navigation.getRouteSegments();
                    if (routeSegments[0] !== relMenuId) {
                        $('[data-menuid="' + relMenuId + '"]', Header.wrapper).trigger('click');
                    }

                    Navigation.isHandlingCurrentMenu = false;
                }
                else {
                    Navigation.lastElementEntered = element;
                }
            }
        },

        getRouteSegments: function(){
            var state = History.getState();
            return Navigation.getRouteSegmentsFromUrl(state.url);
        },

        getRouteSegmentsFromUrl: function(url){
            var relativeUrl = url.replace(Navigation.rootUrl, '');

            // Get URL elements
            var routeSegments = typeof relativeUrl === 'string' ? relativeUrl.split('/') : [];

            return routeSegments;
        },

        setPage: function( url, title ) {
            var relativeUrl = url.replace(Navigation.rootUrl, '');
            relativeUrl = relativeUrl.replace(/\/$/, '');

            // GA Stats
            Navigation.sendStatsToGA(url, title);

            // Get URL elements
            var routeSegments = typeof relativeUrl === 'string' ? relativeUrl.split('/') : [];

            // Update current menu
            Navigation.updateCurrentMenu(relativeUrl, routeSegments);

            // If we have a second segment
            if (routeSegments[1] && routeSegments[1] !== '') {
                switch (routeSegments[0]) {
                    case Navigation.segmentReferences:
                        References.open(url);
                        break;
                }
            } else {
                // Close reference if opened
                if($('body').hasClass('reference-opened')) {
                    References.close();
                }

                // Scroll to element
                if (!Navigation.isHandlingCurrentMenu && !Navigation.isLastSegmentIsSameRoot(routeSegments)) {
                    var element = $('[data-relmenuid="' + routeSegments[0] + '"]');
                    var posTop = 0;
                    if (element.length > 0 && routeSegments[0] !== Navigation.segmentHome) {
                        posTop = Math.max(0, element.position().top);
                    }
                    Main.scrollTo(posTop);

                    // If menu mobile is opened, close it
                    if (Header.menuMobileOpened) {
                        Header.closeMenu();
                    }
                }
            }

            Navigation.lastSegments = routeSegments;
        },

        isLastSegmentIsSameRoot: function (routeSegments) {
            if (typeof Navigation.lastSegments[0] !== 'undefined' && typeof routeSegments[0] !== 'undefined') {
                if (Navigation.lastSegments[0] === routeSegments[0]) {
                    return true;
                }
            }

            return false;
        },

        updateCurrentMenu: function(routeKey, routeSegments){
            $('.current-menu-item').removeClass('current-menu-item').blur();

            var curUrl, curSegment = '';
            for(var i = 0; i < routeSegments.length; i++) {
                if (curSegment !== ''){
                    curSegment += '/';
                }
                curSegment += routeSegments[i];
                curUrl = Navigation.rootUrl + curSegment;

                $('[href="' + curUrl + '"]').each(Navigation.selectMenuItem);
            }
        },

        getSectionHeight: function (menuId) {
            var sectionHeight = $('#' + menuId).outerHeight();
            return sectionHeight;
        },

        updateScenesActiveMode: function () {
            for (var menuId in Navigation.scenesActiveMode) {
                Navigation.scenesActiveMode[menuId].duration(Navigation.getSectionHeight(menuId));
            }
        },

        selectMenuItem: function () {
            var curUrl =  $(this).attr('href');
            var curElement = $('[href="' + curUrl + '"]').closest('li').length > 0 ? $('[href="' + curUrl + '"]').closest('li') : $('[href="' + curUrl + '"]');
            curElement.addClass('current-menu-item');
        },

        getGAUrlToStat: function (url) {
            var relativeUrl = url.replace(Navigation.rootUrl, '');
            relativeUrl = '/' + relativeUrl.replace(/\/$/, '');

            return relativeUrl;
        },

        sendStatsToGA: function (url, title) {
            var urlToStat = Navigation.getGAUrlToStat(url);

            // If page not already viewed
            if ($.inArray(urlToStat, Navigation.pagesViewed) === -1) {
                Navigation.pagesViewed.push(urlToStat);
                ga('send', 'pageview', {
                    'page': urlToStat,
                    'title': title
                });
            }
        },

        onResizeWindow: function (e) {
            waitForFinalEvent(function () {
                Navigation.updateScenesActiveMode();
            }, 250, 'resizeeventnav');
        },

        onScrollWindow: function(){
            // A la fin du scroll, on indique que l'on ne scroll plus
            w.waitForFinalEvent(function(){
                Main.isScrolling = false;

                if(Navigation.lastElementEntered !== null) {
                    Navigation.isHandlingCurrentMenu = true;

                    var routeSegments = Navigation.getRouteSegments();
                    var relMenuId = Navigation.lastElementEntered.data('relmenuid');
                    if(routeSegments[0] !== relMenuId) {
                        $('[data-menuid="' + relMenuId + '"]', Header.wrapper).trigger('click');
                    }

                    Navigation.isHandlingCurrentMenu = false;
                    Navigation.lastElementEntered = null;
                }
            }, 250, 'disableScrollingVar');
        }
    };

})(jQuery, window);