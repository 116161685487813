(function ($, w) {
    w.MainConfig = {
        // ScrollMagic Controller
        scrollMagicCtrl: null,
        // Screen Format helpers
        isMobile: false,
        isDesktop: false,

        defineScreenFormat: function(){
            MainConfig.isMobile = false;
            MainConfig.isDesktop = false;
            if($('.is-mobile').css('display') !== 'none'){
                MainConfig.isMobile = true;
            }
            if($('.is-desktop').css('display') !== 'none'){
                MainConfig.isDesktop = true;
            }
        }
    };

    w.Main = {
        viewport: null,
        isIE: false,
        isIOS: false,
        previousWidth: null,
        durationScrollToDefault: 0.75,
        durationScrollTo: 0,
        isScrolling: false,
        isUserTouching: false,
        aImagesToPreload: [],

        init: function () {
            Main.durationScrollTo = Main.durationScrollToDefault;

            // Check if is ie
            Main.isIE = detectIE();

            // Preload images
            Main.runPreloadImages();

            if (Main.isIE) {
                $('body').addClass('ie');
            }

            // Check if is iOS
            Main.isIOS = detectIOS();
            if (Main.isIOS) {
                $('body').addClass('ios');
            }

            // Screen format
            MainConfig.defineScreenFormat();

            // Init
            MainConfig.scrollMagicCtrl = new ScrollMagic.Controller();
            FastClick.attach(document.body);
            Main.updateViewportDimensions();

            // Init touch state
            w.addEventListener('touchstart', function onFirstTouch() {
                Main.isUserTouching = true;
                // We only need to know once that a human touched the screen, so we can stop listening now
                w.removeEventListener('touchstart', onFirstTouch, false);
            }, false);

            // Init events
            Main.initEvents();

            // Init modules
            Loader.init();
            Stats.init();
            Navigation.init();
            Header.init();
            Home.init();
            References.init();
            Skills.init();
            Footer.init();

            // On initialise les boutons popin
            $('body').popin();

            // Handle responsive images
            Main.handleResponsiveImage($('body'));
        },

        handleResponsiveImage: function (container) {
            // On boucle sur les images responsives
            $('.img-responsive', container).each(function(){
                // On détermine si c'est une image ou non
                var isImage = $(this).is('img') ? true : false;
                // On récupère l'URL actuelle
                var curUrlImage = null;
                if(isImage){
                    curUrlImage = $(this).attr('src');
                }
                else{
                    curUrlImage = $(this).css('background-image');
                    curUrlImage = /^url\((['"]?)(.*)\1\)$/.exec(curUrlImage);
                    curUrlImage = curUrlImage ? curUrlImage[2] : '';
                }
                // On récupère l'URL de l'image à utiliser
                var imgResponsiveFormat = getImgResponsiveFormat($(this));
                var urlImageToUse = $(this).data(imgResponsiveFormat);
                // Si image différente (et non vide)
                if(urlImageToUse && urlImageToUse !== curUrlImage && curUrlImage !== null){
                    // Si c'est une image
                    if(isImage){
                        $(this).attr('src', urlImageToUse);
                    }
                    else{
                        $(this).css('background-image', 'url('+urlImageToUse+')');
                    }
                }
            });
        },

        runPreloadImages: function () {
            for(var i=0, n = Main.aImagesToPreload.length; i < n; i++){
                var curImage = new Image();
                curImage.src = Main.aImagesToPreload[i];
            }
        },

        updateViewportDimensions: function (){
            var body = $('body');
            Main.viewport = {width: body.outerWidth(), height: body.outerHeight()};
            if(Main.previousWidth !== Main.viewport.width) {
                $(window).trigger('resizeWidth');

                Main.previousWidth = Main.viewport.width;
            }
        },

        setHeightContent: function () {
            // Chaque bloc fait au moins la hauteur de l'espace visible alloué au contenu (viewport - header)
            var minHeight = Main.viewport.height - parseInt($('#content').css('top'), 10);
            $('.bloc-content').css('min-height', minHeight);
            // Hauteur du bg du header
            $('.header .bg-global').height(Main.viewport.height);
        },
        
        setNoBorderLeftOnReferences: function () {
            // On retire la classe no-border-left aux références
            $('.references-wrapper .reference').removeClass('no-border-left');
            var curPosTop = null;
            var lastPosTop = null;
            $('.references-wrapper .reference').each(function(){
                curPosTop = $(this).position().top;
                if(curPosTop === null || lastPosTop !== curPosTop){
                    $(this).addClass('no-border-left');
                }
                lastPosTop = curPosTop;
            });
        },
        
        centerHomeContent: function () {
            // Reset bloc home
            $('#home-bloc').css({
                paddingTop: 0,
                paddingBottom: 0
            });
            // Hauteur du bloc home
            var blocHomeHeight = $('#home-bloc').outerHeight();
            // Hauteur du contenu home
            var blocHomeContentHeight = $('#home-bloc .bloc-content-inner').outerHeight();
            // Padding top/bottom
            var blocHomePadding = Math.max(Math.round((blocHomeHeight - blocHomeContentHeight) / 2), 20);
            // Application du padding calculé
            $('#home-bloc').css({
                paddingTop: blocHomePadding,
                paddingBottom: blocHomePadding
            });
        },

        initEvents: function(){
            // Bind window resize
            $(window).on('resize', Main.onResizeWindow);
        },

        scrollTo: function(posTop){
            Main.isScrolling = true;
            TweenLite.to(w, Main.durationScrollTo, {scrollTo:{y: posTop, autoKill:false}, ease:Power2.easeOut, onComplete: function(){
                Main.isScrolling = false;
            }});
            Main.durationScrollTo = Main.durationScrollToDefault;
        },

        onResizeWindow: function(){
            w.waitForFinalEvent(function(){
                Main.updateViewportDimensions();

                MainConfig.defineScreenFormat();

                Main.setHeightContent();

                Main.setNoBorderLeftOnReferences();

                Main.centerHomeContent();

                Main.handleResponsiveImage($('body'));

                Navigation.updateScenesActiveMode();
            }, 100, 'resizeWindowMain');
        }
    };

    w.getImgResponsiveFormat = function(pImage){
        // On récupère la largeur de l'écran
        var windowWidth = Main.viewport.width;
        var imgResponsiveFormat = 'imgresponsive-mobile-portrait';
        switch(true){
            case windowWidth >= 1620:
                imgResponsiveFormat = 'imgresponsive-large';
                break;
            case windowWidth >= 1300:
                imgResponsiveFormat = 'imgresponsive-desktop';
                break;
            case windowWidth >= 980:
                imgResponsiveFormat = 'imgresponsive-tablet-landscape';
                break;
            case windowWidth >= 660:
                imgResponsiveFormat = 'imgresponsive-tablet-portrait';
                break;
            case windowWidth >= 500:
                imgResponsiveFormat = 'imgresponsive-mobile-landscape';
                break;
        }
        return imgResponsiveFormat;
    };

    /*
     * Throttle Resize-triggered Events
     * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
     * ( source: http://stackoverflow.com/questions/2854407/javascript-$-window-resize-how-to-fire-after-the-resize-is-completed )
     */
    w.waitForFinalEvent = (function () {
        var timers = {};
        return function (callback, ms, uniqueId) {
            if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
            if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
            timers[uniqueId] = setTimeout(callback, ms);
        };
    })();

    w.detectIE = function(){
        var ua = window.navigator.userAgent;
 
        var msie = ua.indexOf('MSIE '); //IE10 or older
        var trident = ua.indexOf('Trident/'); // IE11
        var edge = ua.indexOf('Edge/'); // IE12 - Edge

        return msie > 0 || trident > 0 || edge > 0;
    };

    w.detectIOS = function(){
        var ua = window.navigator.userAgent.toLowerCase();
        var android = ua.indexOf('android') > 0;
        var iphone = !android && (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0);
        var ipad = !android && !iphone && ua.indexOf('ipad') > 0;
        var ios = iphone || ipad;

        return ios;
    };

    // Responsive Image
    $.fn.responsiveImage = function(){
        var $this = $(this);
        Main.handleResponsiveImage($this);

        // Chain
        return $this;
    };

    $(document).ready(Main.init);

})($, window);

$(window).on('load', function(){
    // On termine le loader de page
    Loader.siteLoaded = true;
    $(window).trigger('resize');
});