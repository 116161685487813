(function ($, w) {
    w.Footer = {
        wrapper: $('footer.footer'),
        sceneScrolled: null,
        heightScrolled: 0,

        init: function () {
            Footer.initScrolled();

            Footer.initEvents();
        },

        initScrolled: function(){
            Footer.sceneScrolled = new ScrollMagic.Scene({
                triggerElement: '#contact-bloc-fake',
                triggerHook: 'onEnter',
                duration: Footer.getFooterHeight()
            })
                .setTween('.bg-global', {y: -Footer.getFooterHeight(), ease: Linear.easeNone})
                .addTo(MainConfig.scrollMagicCtrl);
        },

        initEvents: function(){
            // Window resize
            $(w).on('resize', Footer.onResizeWindow);
        },

        getFooterHeight: function () {
            return Footer.wrapper.outerHeight();
        }
    };

})($, window);