(function ($, w) {
    w.Header = {
        wrapper: $('header.header'),
        menuMobileOpened: false,
        sceneScrolled: null,
        heightScrolled: 0,

        init: function () {
            Header.setHeightScrolled();

            Header.initScrolledHeader();

            Header.initEvents();
        },

        initScrolledHeader: function(){
            // Header scrolled
            Header.sceneScrolled = new ScrollMagic.Scene({
                offset: 1
            })
              .setClassToggle('header.header', 'scrolled')
              .addTo(MainConfig.scrollMagicCtrl);
        },

        initEvents: function(){
            // Window resize
            $(w).on('resize', Header.onResizeWindow);
            // Clic sur le bouton menu
            $('.btn-menu-mobile').on('click', Header.onClickBtnMenu);
        },

        openMenu: function(){
            Header.menuMobileOpened = true;

            $('.header .menu-wrapper').show();
            // On détermine la position du menu afin qu'il soit centré
            var heightMenu = $('.header .menu-wrapper ul').outerHeight();
            var posTop = (Main.viewport.height - heightMenu) / 2;

            setTimeout(function(){
                $('.header .menu-wrapper ul').css('top', posTop);
                $('.header .menu-wrapper').addClass('shown');
            }, 0);
            // On ajoute la classe close au bouton menu
            $('.header .btn-menu-mobile').addClass('close');

            $(document).on('click', Header.onClickDocumentForMenu);
        },

        closeMenu: function(){
            Header.menuMobileOpened = false;

            // On retire la classe shown au menu
            $('.header .menu-wrapper').removeClass('shown');
            setTimeout(function(){
                $('.header .menu-wrapper').hide();
            }, 250);
            // On retire la classe close au bouton menu
            $('.header .btn-menu-mobile').removeClass('close');
            
            $(document).off('click', Header.onClickDocumentForMenu);
        },

        setHeightScrolled: function () {
            Header.heightScrolled = Header.wrapper.outerHeight();
        },

        onClickDocumentForMenu: function(e){
            if($(e.target).closest('header.header .menu-wrapper').length === 0 && $(e.target).closest('.btn-menu-mobile').length === 0) {
                Header.closeMenu();
            }
        },

        onClickBtnMenu: function(e){
            if(!Header.menuMobileOpened){
                Header.openMenu();
            } else {
                Header.closeMenu();
            }
        },

        onResizeWindow: function (e) {
            w.waitForFinalEvent(function(){
                Header.setHeightScrolled();
            }, 250, 'resizeWindowHeader');
        }
    };

})($, window);