(function ($, w) {
    w.Loader = {
        wrapper: null,
        C: null,
        c: null,
        viewWidth: 0,
        viewHeight: 0,
        triW: 26,
        triH: 26,
        speedGroupAppear: 0.07,
        speedGroupDisappear: 0.07,
        speedTriOpen: 1,
        itvGroup: null,
        tGroupsToUse: null,
        groupIntervalShow: 100,
        delayBeforeDisappear: 2.5,
        cols: 0,
        rows: 0,
        tris: [],
        numTriClosed: 0,
        siteLoaded: false,
        trisHaveToClose: true,
        offsetX: 68,
        offsetY: 22,

        colors: [{code: '#0d435b', alpha: 1}, {code: '#00729d', alpha: 1}, {code: '#10a0d6', alpha: 1}, {code: '#20b3e2', alpha: 1}],

        triangles: [
            {top: false, left: false, color: 1, row: 2, col: 1, offsetX: -13, offsetY: 0}, // 0
            {top: true, left: false, color: 1, row: 3, col: 1, offsetX: -13, offsetY: 0},
            {top: false, left: false, color: 0, row: 0, col: 0, offsetX: 0, offsetY: 0},
            {top: true, left: true, color: 1, row: 1, col: 0, offsetX: 0, offsetY: 0},
            {top: false, left: false, color: 2, row: 1, col: 0, offsetX: 0, offsetY: 0},
            {top: true, left: true, color: 3, row: 2, col: 0, offsetX: 0, offsetY: 0}, // 5
            {top: false, left: false, color: 2, row: 2, col: 0, offsetX: 0, offsetY: 0},
            {top: true, left: false, color: 2, row: 3, col: 0, offsetX: 0, offsetY: 0},
            {top: false, left: true, color: 3, row: 3, col: 0, offsetX: 0, offsetY: 0},
            {top: true, left: false, color: 2, row: 4, col: 0, offsetX: 0, offsetY: 0},
            {top: false, left: true, color: 1, row: 4, col: 0, offsetX: 0, offsetY: 0}, // 10
            {top: true, left: true, color: 0, row: 5, col: 0, offsetX: 0, offsetY: 0},
            {top: false, left: false, color: 3, row: 1, col: 2, offsetX: -13, offsetY: 0},
            {top: true, left: true, color: 2, row: 2, col: 2, offsetX: -13, offsetY: 0},
            {top: true, left: false, color: 3, row: 4, col: 2, offsetX: -13, offsetY: 0},
            {top: false, left: true, color: 2, row: 3, col: 2, offsetX: -13, offsetY: 0} // 15
        ],

        tGroups: [
            [2, 3, 4, 5],
            [8, 9, 10, 11],
            [6, 7, 0, 1],
            [12, 13],
            [14, 15]
        ],

        init: function () {
            Loader.wrapper = $('#page-loader');

            Loader.C = document.getElementById('canvas-logo-anim');
            Loader.c = Loader.C.getContext('2d');

            Loader.initParams();
            Loader.initGrid();

            Loader.setSizes();

            TweenLite.ticker.addEventListener("tick", Loader.draw);

            Loader.start();
        },

        initParams: function () {
            Loader.cols = Math.floor(Loader.viewWidth / Loader.triW);
            Loader.cols = (Loader.cols % 2) ? Loader.cols : Loader.cols - 1;
            Loader.rows = Math.floor(Loader.viewHeight / Loader.triH) * 2;
        },

        initGrid: function () {
            for (var i = 0; i < Loader.triangles.length; i++) {
                var tObj = Loader.triangles[i];
                var pos = tObj.col + (tObj.row * Loader.cols);
                var triangle = new Loader.Triangle(tObj, pos);
                Loader.tris.push(triangle);
                triangle.draw();
            }
        },

        setSizes: function () {
            Loader.viewWidth = Loader.C.width = Loader.C.scrollWidth;
            Loader.viewHeight = Loader.C.height = Loader.C.scrollHeight;
        },

        start: function () {
            Loader.numTriClosed = 0;
            Loader.tGroupsToUse = Loader.tGroups.slice(0);
            Loader.shuffleArray(Loader.tGroupsToUse);

            if (Loader.itvGroup) {
                clearInterval(Loader.itvGroup);
            }

            Loader.itvGroup = setInterval(Loader.showFirstTGroup, Loader.groupIntervalShow);
            Loader.showFirstTGroup();
        },

        Triangle: function(tObj, pos) {
            this.pos = pos;
            this.isTop = tObj.top;
            this.isLeft = tObj.left;
            this.col = tObj.col;
            this.row = tObj.row;
            this.rotate = tObj.rotate;
            this.color = Loader.colors[tObj.color];
            this.rgb = Loader.hexToRgb(this.color.code);
            this.alpha = this.tAlpha = this.color.alpha;

            if(this.rotate) {
                var hypo = Math.sqrt( Math.pow(Loader.triW, 2) + Math.pow(Loader.triH, 2));

                if(this.rotate === 45) {
                    this.tX1 = this.col * Loader.triW + hypo / 2;
                    this.tX2 = this.col * Loader.triW;
                    this.tX3 = this.col * Loader.triW;
                } else {
                    this.tX1 = this.col * Loader.triW;
                    this.tX2 = this.col * Loader.triW + hypo / 2;
                    this.tX3 = this.col * Loader.triW + hypo / 2;
                }

                this.tY1 = this.row * Loader.triH + hypo / 2;
                this.tY2 = this.row * Loader.triH + hypo;
                this.tY3 = this.row * Loader.triH;
            }
            else {
                this.tX1 = this.isLeft ? this.col * Loader.triW : (this.col + 1) * Loader.triW;
                this.tX2 = this.isLeft ? this.col * Loader.triW : (this.col + 1) * Loader.triW;
                this.tX3 = this.isLeft ? (this.col + 1) * Loader.triW : this.col * Loader.triW;

                this.tY1 = this.isTop ? this.row * Loader.triH : (this.row + 1) * Loader.triH;
                this.tY2 = this.isTop ? (this.row + 1) * Loader.triH : this.row * Loader.triH;
                this.tY3 = this.isTop ? this.row * Loader.triH : (this.row + 1) * Loader.triH;
            }

            this.tX1 += Loader.offsetX;
            this.tX2 += Loader.offsetX;
            this.tX3 += Loader.offsetX;

            if(tObj.offsetX){
                this.tX1 += tObj.offsetX;
                this.tX2 += tObj.offsetX;
                this.tX3 += tObj.offsetX;
            }

            this.x1 = this.tX1;
            this.x2 = this.tX1;
            this.x3 = this.tX1;

            this.tY1 += Loader.offsetY;
            this.tY2 += Loader.offsetY;
            this.tY3 += Loader.offsetY;

            if(tObj.offsetY){
                this.tY1 += tObj.offsetY;
                this.tY2 += tObj.offsetY;
                this.tY3 += tObj.offsetY;
            }

            this.y1 = this.tY1;
            this.y2 = this.tY1;
            this.y3 = this.tY1;

            this.tweenClose = false;
            this.tweenOpen = false;
            this.draw = function() {
                Loader.c.fillStyle = 'rgba(' + this.rgb.r + ',' + this.rgb.g + ',' + this.rgb.b + ',' + this.alpha + ')';
                Loader.c.beginPath();
                Loader.c.moveTo(this.x1, this.y1);
                Loader.c.lineTo(this.x2, this.y2);
                Loader.c.lineTo(this.x3, this.y3);
                Loader.c.closePath();
                Loader.c.fill();
            };

            this.open = function(direction, targetSpeed, targetDelay) {
                this.direction = direction || "top";
                this.delay = targetDelay || 0;
                this.tSpeed = targetSpeed || 1.5;
                if (this.direction === "side") {
                    this.x1 = this.x2 = this.x3 = this.tX1;
                    this.y1 = this.tY1;
                    this.y2 = this.tY2;
                    this.y3 = this.tY3;
                } else if (this.direction === "top") {
                    this.x1 = (this.tX2 + this.tX3) / 2;
                    this.x2 = this.tX2;
                    this.x3 = this.tX3;
                    this.y1 = (this.tY2 + this.tY3) / 2;
                    this.y2 = this.tY2;
                    this.y3 = this.tY3;
                } else if (this.direction === "bottom") {
                    this.x1 = this.tX1;
                    this.x2 = this.tX2;
                    this.x3 = (this.tX1 + this.tX2) / 2;
                    this.y1 = this.tY1;
                    this.y2 = this.tY2;
                    this.y3 = (this.tY1 + this.tY2) / 2;
                }
                this.tweenOpen = TweenMax.to(this, this.tSpeed, {
                    x1: this.tX1,
                    x2: this.tX2,
                    x3: this.tX3,
                    y1: this.tY1,
                    y2: this.tY2,
                    y3: this.tY3,
                    alpha: this.tAlpha,
                    ease: Strong.easeOut,
                    delay: this.delay
                });
            };

            this.close = function(direction, targetSpeed, targetDelay) {
                this.direction = direction || "top";
                this.delay = targetDelay || 1;
                this.tSpeed = targetSpeed || 0.8;
                var closeX1, closeX2, closeX3, closeY1, closeY2, closeY3;

                if (this.direction === "side") {
                    closeX1 = closeX2 = closeX3 = this.tX1;
                    closeY1 = this.tY1;
                    closeY2 = this.tY2;
                    closeY3 = this.tY3;
                } else if (this.direction === "top") {
                    closeX1 = (this.tX2 + this.tX3) / 2;
                    closeX2 = this.tX2;
                    closeX3 = this.tX3;
                    closeY1 = (this.tY2 + this.tY3) / 2;
                    closeY2 = this.tY2;
                    closeY3 = this.tY3;
                } else if (this.direction === "bottom") {
                    closeX1 = this.tX1;
                    closeX2 = this.tX2;
                    closeX3 = (this.tX1 + this.tX2) / 2;
                    closeY1 = this.tY1;
                    closeY2 = this.tY2;
                    closeY3 = (this.tY1 + this.tY2) / 2;
                }
                if (this.tweenClose) {
                    this.tweenClose.kill();
                }
                this.tweenClose = TweenMax.to(this, this.tSpeed, {
                    x1: closeX1,
                    x2: closeX2,
                    x3: closeX3,
                    y1: closeY1,
                    y2: closeY2,
                    y3: closeY3,
                    alpha: 0,
                    ease: Strong.easeIn,
                    delay: this.delay,
                    onComplete: Loader.closeComplete
                });
            };
        },

        closeComplete: function (){
            Loader.numTriClosed++;

            if(Loader.numTriClosed >= Loader.triangles.length){
                if(Loader.siteLoaded){
                    Loader.trisHaveToClose = false;

                    $('#global').removeClass('loading');
                    $(window).trigger('resize');

                    setTimeout(function () {
                        if($('#home-bloc .logo').length > 0) {
                            // On fait remonter le loader sur la position du logo de la home
                            var formesLoader = $('#canvas-logo-anim', Loader.wrapper);
                            var offsetTopLogoHome = $('#home-bloc .logo').offset().top;
                            var offsetTopLoader = formesLoader.offset().top;

                            var logoHeight = $('#home-bloc .logo').outerHeight();
                            var animLogoHeight = formesLoader.outerHeight();
                            var animScale = logoHeight / animLogoHeight;

                            var objectAnim = {
                                ease: Power1.easeOut,
                                onComplete: function () {
                                    TweenLite.to(Loader.wrapper, 0.5, {
                                        css: {opacity: 0}, ease: Power1.easeOut, onComplete: function () {
                                            $('#global').addClass('loaded');
                                            Loader.wrapper.remove();

                                            Navigation.initInitialPage();
                                        }
                                    });
                                }
                            };

                            objectAnim.force3D = true;
                            objectAnim.scale = animScale;
                            objectAnim.transformOrigin = "center top";
                            objectAnim.y = Math.round(offsetTopLogoHome - offsetTopLoader);

                            TweenLite.to(formesLoader, 0.5, objectAnim);
                        } else {
                            TweenLite.to(Loader.wrapper, 0.5, {
                                css: {opacity: 0}, ease: Power1.easeOut, onComplete: function () {
                                    $('#global').addClass('loaded');
                                    Loader.wrapper.remove();
                                }
                            });
                        }
                    }, 1500);
                }

                Loader.start();
            }
        },

        showFirstTGroup: function (){
            var tGroup = Loader.tGroupsToUse.shift();
            if(tGroup){
                for(var i = 0; i < tGroup.length; i++){
                    var curTri = Loader.tris[tGroup[i]];
                    var openDir = curTri.openDir ? curTri.openDir : 'bottom';
                    var closeDir = curTri.closeDir ? curTri.closeDir : 'top';
                    curTri.open(openDir, Loader.speedTriOpen, i * Loader.speedGroupAppear);
                    if(Loader.trisHaveToClose) {
                        curTri.close(closeDir, 1, Loader.delayBeforeDisappear + i * Loader.speedGroupDisappear);
                    }
                }
            }
            else{
                clearInterval(Loader.itvGroup);
            }
        },

        draw: function () {
            Loader.c.clearRect(0, 0, Loader.C.width, Loader.C.height);
            for (var i = 0; i < Loader.tris.length; i++) {
                Loader.tris[i].draw();
            }
        },

        hexToRgb: function (hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },

        shuffleArray: function (o) {
            for (var i = o.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = o[i];
                o[i] = o[j];
                o[j] = temp;
            }
            return o;
        }
    };
})($, window);
