(function ($, w) {
    w.Skills = {
        wrapper: $('#competences-bloc'),

        init: function () {
            Skills.initSlider();

            Skills.initScrolled();

            Skills.initEvents();
        },

        initSlider: function () {
            Skills.wrapper.width(Main.viewport.width);

            $('.slider', Skills.wrapper)
                .slick({
                    focusOnSelect: true,
                    arrows: false,
                    dots: true,
                    mobileFirst: true,
                    touchThreshold: 30,
                    responsive: [
                        {
                            breakpoint: 660,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 980,
                            settings: 'unslick'
                        }
                    ]
                });
        },

        initScrolled: function(){
            var elements = $('.slider-wrapper .item', Skills.wrapper);

            for(var i=0; i < elements.length; i++){
                new ScrollMagic.Scene({
                    triggerElement: elements[i],
                    triggerHook: 0.85,
                    reverse: false
                })
                    .setClassToggle(elements[i], 'shown')
                    .addTo(MainConfig.scrollMagicCtrl);
            }
        },

        initEvents: function(){
            // Window resize
            $(w).on('resize', Skills.onResizeWindow);
        },

        onResizeWindow: function (e) {
            w.waitForFinalEvent(function(){
                Skills.wrapper.width(Main.viewport.width);

                if(!$('.slider', Skills.wrapper).hasClass('slick-initialized')) {
                    Skills.initSlider();
                }
            }, 250, 'resizeWindowSkills');
        }
    };

})($, window);