(function ($, w) {
    w.Stats = {
        init: function () {
            Stats.initEvents();
        },

        initEvents: function () {
        },

        sendToGA: function (category, action, label) {
            var eventData = {
                hitType: 'event',
                eventCategory: category,
                eventAction: action,
                eventLabel: label
            };

            ga('send', eventData);
        }
    };

})(jQuery, window);