(function($){
    $.popin = function(el, options){
        // To avoid scope issues, use 'base' instead of 'this'
        // to reference this class from internal events and functions.
        var base = this;
        
        // Access to jQuery and DOM versions of element
        base.$el = $(el);
        base.el = el;
        
        base.isShown = false;
        base.popinIdShown = null;
        
        // Add a reverse reference to the DOM object
        base.$el.data("popin", base);
        
        base.init = function(){
            base.options = $.extend({}, $.popin.defaultOptions, options);
            
            // On initialise les évènements
            initEvents();
        };
        
		var initEvents = function(){
			// On écoute le clic sur les boutons popin
			$(document).on('click', '.btn-popin', base.clickBtnPopinHandler);
			
			// On écoute le clic sur l'overlay de la popin
			$('#popin-overlay').bind('click', base.clickPopinOverlayHandler);
			
			// On écoute le clic sur les boutons de fermeture de popin
			$('.btn-close-popin').bind('click', base.clickBtnClosePopinHandler);
			
			// On écoute le clavier dans le document
			$(document).bind('keyup', base.keyupDocumentHandler);
		};
		
		base.showPopin = function(popinId){
			// Si elle existe
			if($('#'+popinId).length > 0){
				base.isShown = true;
				base.popinIdShown = popinId;
				
				// On masque toutes les popins
				$('.popin').hide();
				
				// On prépare la popin à afficher pour l'animation
				TweenLite.to($('#'+popinId), 0, {css: {opacity: 0}});
				$('#'+popinId).show();
				
				// On affiche l'overlay
				$('#popin-overlay').css('opacity', 0).show();
				TweenLite.to($('#popin-overlay'), 0.25, {opacity: 1, onComplete: function(){
					// On affiche la popin
					TweenLite.to($('#'+popinId), 0.25, {css: {opacity: 1}, ease: Power1.easeOut, onComplete: function(){
						// On déclenche un évènement pour indiquer que la popin est affichée
						$('#'+popinId).trigger('popin.shown');
					}});
				}});
			}
		};
		
		base.hidePopin = function(){
			base.isShown = false;
			
			// On masque la popin
			TweenLite.to($('#'+base.popinIdShown), 0.25, {opacity: 0, onComplete: function(){
				$('#'+base.popinIdShown).hide();
				// On déclenche un évènement pour indiquer que la popin est masquée
				$('#'+base.popinIdShown).trigger('popin.hidden');
			}});
			
			// On masque l'overlay
			TweenLite.to($('#popin-overlay'), 0.25, {opacity: 0, onComplete: function(){
				$('#popin-overlay').removeClass('black');
				$('#popin-overlay').hide();
			}});
		};
		
		// HANDLERS
		base.clickBtnPopinHandler = function(e){
			// On récupère l'id de la popin à afficher
			var curIdPopinToShow = $(e.currentTarget).data('popinid');
			
			// On affiche la popin
			base.showPopin(curIdPopinToShow);
		};
		
		base.clickPopinOverlayHandler = function(e){
			// On masque la popin
			base.hidePopin();
		};
		
		base.clickBtnClosePopinHandler = function(e){
			// On masque la popin
			base.hidePopin();
		};
		
		base.keyupDocumentHandler = function(e){
			// Si la popin est affichée et que l'on a appuyé sur ESC
			if(base.isShown && e.keyCode == 27){
				// On masque la popin
				base.hidePopin();
			}
		};
		
		var debug = function(message){
			if(window.console && window.console.log){
				console.log('[popin] '+message);
			}
		};
        
        // Run initializer
        base.init();
    };
    
    $.popin.defaultOptions = {
    	
    };
    
    $.fn.popin = function(options){
        return this.each(function(){
            (new $.popin(this, options));
        });
    };
    
})(jQuery);