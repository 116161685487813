(function ($, w) {
    w.Home = {
        init: function () {
            Home.initEvents();
        },

        initEvents: function () {
        }
    };

})($, window);